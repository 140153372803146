import React, { useState } from "react";
import { Dialog, DialogContent } from "design-system/components/ui/dialog";
import { Button } from "design-system/components/ui-v2";
import { Icon } from "@iconify/react";
type Props = {
  open: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
};

// Define carousel item type
type CarouselItem = {
  image: string;
  heading: string;
  description: string;
};
const carouselItems: CarouselItem[] = [{
  image: require("../../../assets/image-74.png"),
  // Add your image paths
  heading: "Sell Globally. Collect Locally.",
  description: "Get unlimited incoming transfers in USD, GBP, EUR and CAD."
}, {
  image: require("../../../assets/image-73.png"),
  heading: "<1% Transfer fee. No Surprises.",
  description: "Zero fx margin. No hidden fees. 1-day settlement."
}, {
  image: require("../../../assets/image-77.png"),
  heading: "Be compliant with e-FIRA.",
  description: "Get a free FIRA from an AD-1 bank with every withdrawal. Simplify compliance and grow effortlessly."
}, {
  image: require("../../../assets/image-76.png"),
  heading: "No Follow-ups. Track Payments.",
  description: " Manage invoices, send reminders, and track statuses in one dashboard with real-time updates."
}
// Add more items as needed
];
const UploadInvoiceDialog = (props: Props) => {
  const {
    open,
    onClose,
    onSubmit
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  // Auto-navigate every 10 seconds
  React.useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide(prev => (prev + 1) % carouselItems.length);
    }, 4000);

    // Cleanup timer on component unmount or dialog close
    return () => clearInterval(timer);
  }, []);
  return <Dialog open={open} onOpenChange={onClose} data-sentry-element="Dialog" data-sentry-component="UploadInvoiceDialog" data-sentry-source-file="KYCSubmitted.tsx">
      <DialogContent className="w-full max-w-[850px]" showCloseButton={false} data-sentry-element="DialogContent" data-sentry-source-file="KYCSubmitted.tsx">
        <div className="flex flex-col items-center justify-center py-6 text-center">
          <div className="flex flex-col items-center justify-center space-y-4">
            <p className="text-3xl font-semibold text-[#243547] ">
              We are setting up your Global payments account.
            </p>
            <p className="text-base text-[#40566D]">
              Discover the key features that make managing global payments
              effortless.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center space-y-4">
            <img src={carouselItems[currentSlide].image} alt={carouselItems[currentSlide].heading} className="w-full max-w-[600px] h-[300px] object-contain mb-6" />
            <p className="text-2xl font-semibold text-[#243547] max-w-[600px]">
              {carouselItems[currentSlide].heading}
            </p>
            <p className="text-base text-[#40566D] max-w-[600px]">
              {carouselItems[currentSlide].description}
            </p>

            {/* Navigation dots */}
            <div className="flex space-x-2 mt-6">
              {carouselItems.map((_, index) => <button key={index} onClick={() => setCurrentSlide(index)} className={`w-2 h-2 rounded-full transition-all ${currentSlide === index ? "bg-blue-600 w-8" : "bg-gray-300"}`} />)}
            </div>
          </div>
          <Button icon={<Icon icon="material-symbols:logout" />} variant="ghost" className="w-full max-w-[200px] mt-2" onClick={onSubmit} data-sentry-element="Button" data-sentry-source-file="KYCSubmitted.tsx">
            <p>Logout</p>
          </Button>
        </div>
      </DialogContent>
    </Dialog>;
};
export default UploadInvoiceDialog;